
@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@tailwind components;

/**
 * Tailwind's utility classes, generated based on your config file.
 */

@tailwind utilities;

a {
    @apply underline
}

.payment-table{
    @apply table-auto
}

.payment-table td{
    @apply py-5
}

.payment-table tr:hover{
    @apply bg-gray-200 cursor-pointer
}


.payment-table tr.cancelled td{
    @apply line-through
}

/* 
html {
    font-size: 62.5%;
    font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #121212;
}


body {
    font-size: 10px;
    line-height: 1.4;
    overflow-x: hidden;
    font-feature-settings: 'salt';
} */